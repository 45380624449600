import Vue from "vue";
import VueRouter from "vue-router";
import login from "../views/login.vue";
import Home from "../views/index.vue";

Vue.use(VueRouter);

//解决重复点击路由报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "main",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: login,
    meta: {
      name: "登录",
    },
  },
  {
    path: "/phoneOrder/index",
    name: "phoneOrder",
    component: () => import("@/views/order/phoneOrder"),
    meta: {
      name: "订单列表",
    },
  },
  {
    path: "/phoneTableList/index",
    name: "phoneTableList",
    component: () => import("@/views/phoneTableList/index"),
    meta: {
      name: "手机端麻将桌列表",
    },
  },
  {
    path: "/home",
    name: "home",
    redirect: "/home/index",
    component: Home,
    children: [
      {
        path: "/user/index",
        name: "user",
        component: () => import("@/views/user/index"),
        meta: {
          name: "用户列表",
        },
      },
      {
        path: "/activity/index",
        name: "activity",
        component: () => import("@/views/activity/index"),
        meta: {
          name: "活动列表",
        },
      },
      {
        path: "/carousel/index",
        name: "carousel",
        component: () => import("@/views/carousel/index"),
        meta: {
          name: "轮播列表",
        },
      },
      {
        path: "/article/index",
        name: "article",
        component: () => import("@/views/article/index"),
        meta: {
          name: "地址列表",
        },
      },
      {
        path: "/order/index",
        name: "order",
        component: () => import("@/views/order/index"),
        meta: {
          name: "订单列表",
        },
      },
      {
        path: "/selfPoint/index",
        name: "selfPoint",
        component: () => import("@/views/selfPoint/index"),
        meta: {
          name: "自提点列表",
        },
      },
      {
        path: "/tixian/index",
        name: "tixian",
        component: () => import("@/views/tixian/index"),
        meta: {
          name: "提现列表",
        },
      },
      {
        path: "/classify/index",
        name: "classify",
        component: () => import("@/views/classify/index"),
        meta: {
          name: "分类列表",
        },
      },
      {
        path: "/mahjong/index",
        name: "mahjong",
        component: () => import("@/views/mahjong/index"),
        meta: {
          name: "麻将桌列表",
        },
      },
      {
        path: "/jifen/index",
        name: "jifen",
        component: () => import("@/views/jifen/index"),
        meta: {
          name: "积分列表",
        },
      },
      {
        path: "/config/index",
        name: "config",
        component: () => import("@/views/config/index"),
        meta: {
          name: "基本设置",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
