import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import getAjax from "@/utils/requestAjax";
import "./utils/filter.js";
import "@/assets/less/common.less";
import "vant/lib/index.css";
import ElementUI from "element-ui";
import Vant from 'vant';
import "element-ui/lib/theme-chalk/index.css";
import '@/utils/defaultFunc.js' //默认方法

Vue.use(ElementUI);
Vue.use(Vant);

Vue.config.productionTip = false;

// 挂载request
Vue.prototype.getAjax = getAjax;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
