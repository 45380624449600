<template>
  <div class="tianfulove">
    <div class="leftBox">
      <el-menu
        :default-active="defaultActive"
        class="el-menu-vertical-demo"
        @select="handleSelect"
        @open="handleOpen"
        @close="handleClose"
        background-color="#545c64"
        text-color="#fff"
        active-text-color="#ffd04b"
      >
        <el-menu-item index="user" v-if="power==='1'">
          <i class="el-icon-menu"></i>
          <span slot="title">用户列表</span>
        </el-menu-item>
        <el-menu-item index="activity" v-if="power==='1'">
          <i class="el-icon-menu"></i>
          <span slot="title">活动列表</span>
        </el-menu-item>
        <el-menu-item index="carousel" v-if="power==='1'">
          <i class="el-icon-menu"></i>
          <span slot="title">轮播列表</span>
        </el-menu-item>
        <el-menu-item index="article" v-if="power==='1'">
          <i class="el-icon-menu"></i>
          <span slot="title">文章列表</span>
        </el-menu-item>
        <el-menu-item index="order" v-if="power==='1'">
          <i class="el-icon-menu"></i>
          <span slot="title">订单列表</span>
        </el-menu-item>
        <el-menu-item index="selfPoint" v-if="power==='1'">
          <i class="el-icon-menu"></i>
          <span slot="title">自提点列表</span>
        </el-menu-item>
        <el-menu-item index="tixian" v-if="power==='1'">
          <i class="el-icon-menu"></i>
          <span slot="title">提现列表</span>
        </el-menu-item>
        <el-menu-item index="classify" v-if="power==='1'">
          <i class="el-icon-menu"></i>
          <span slot="title">分类列表</span>
        </el-menu-item>
        <el-menu-item index="mahjong">
          <i class="el-icon-menu"></i>
          <span slot="title">麻将桌列表</span>
        </el-menu-item>
        <el-menu-item index="jifen" v-if="power==='1'">
          <i class="el-icon-menu"></i>
          <span slot="title">积分列表</span>
        </el-menu-item>
        <el-menu-item index="config" v-if="power==='1'">
          <i class="el-icon-menu"></i>
          <span slot="title">基本设置</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="rightBox">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      power:'2',
      defaultActive: "user",
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    handleSelect(name) {
      this.$router.push({
        name,
      });
    },
  },
  mounted() {
    this.defaultActive = this.$route.name;
    this.power = localStorage.getItem('power');
  },
};
</script>

<style lang="less">
.tianfulove {
  width: 100%;
  height: 100%;
  display: flex;
  .leftBox {
    width: 10%;
    min-width: 130px;
  }
  .rightBox {
    flex: 1;
    background: #fff;
    overflow-y: auto;
    padding: 20px;
    .fenye {
      text-align: right;
      padding: 15px 10px;
    }
  }
}
</style>
